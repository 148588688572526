<template>
  <div>
    <b-button size="sm" variant="info" v-b-modal.feeSettings>Fee Settings...</b-button>
    <b-modal id="feeSettings" title="Fee Settings per Transaction" ok-title="Modify" @ok="modifyFee">
        <b-row >
            <b-col sm="6">
            <label for="input-none">Merchant Fee</label>
            </b-col>
            <b-col sm="6">
            <b-form-input v-model="merchantFee" ></b-form-input>
            </b-col>
        </b-row>
        <b-row >
            <b-col sm="6">
            <label for="input-none">Blockchain Fee</label>
            </b-col>
            <b-col sm="6">
            <b-form-input type="number" v-model="blockChainFee" ></b-form-input>
            </b-col>
        </b-row>

    </b-modal>

  </div>
</template>
<script>
export default {
  name: 'ConfigureFee',
  props: ["currency", "userList"],
  data: function() {
      return {
          merchantFee:0,
          blockChainFee:0
      };
  },
  watch: {
      userList: function(ul) {
          const wf = ul.withdrawalFee;
          this.merchantFee = wf.merchantFeePerTx;
          this.blockChainFee = wf.blockchainFeePerTransfer;
      }
  },
  methods: {
      modifyFee:function() {
          this.$root.hapi.setFee(this.currency, this.merchantFee, this.blockChainFee, (err, data)=>{
              console.log("setFee response %o", data);

              if(err) this.$root.app.showError(err);
              else this.$emit("modifyFee");
          });
      }
  }
}
</script>
