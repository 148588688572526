<template>
  <div class="c">
    <b-nav>
      <p>Total <span class="totalMoney">{{ depositBalance }}</span></p>
      <p>Merchant <span class="totalMoney">{{ merchantBalance }}</span></p>
      <!--<p>Blockchain <span class="money">{{ blockchainBalance }}</span>|</p>-->
      <b-checkbox v-model="positiveOnly">Show accounts with any balance</b-checkbox>
    </b-nav>
    <b-nav v-if="this.currency=='USDT'">
      <p>GasSource Balance <span class="totalMoney">{{ gasSourceBalance }}</span> ETH</p>
      <p>GasSource Address <a target="_blank" class="address" :href="addressLink(gasSourceAddress)">{{ shortAddress(gasSourceAddress) }} </a></p>
    </b-nav>
    <b-nav>
      <b-nav-form>
        <b-nav-item>
          <b-button variant="info" size="sm" v-b-modal.addaccount>Add Account</b-button>
        </b-nav-item>
        <b-nav-item>
          <b-button variant="info" size="sm" @click="refreshButton()">Refresh</b-button>
        </b-nav-item>
        <b-nav-item>
          <b-button variant="info" size="sm" @click="withdrawHistoryButton()">Withdraw History</b-button>
        </b-nav-item>
        <b-nav-item>
          <ConfigreFee :userList="userList" :currency="currency" @modifyFee="refresh()"/>
        </b-nav-item>
        <b-nav-item v-if="this.currency=='USDT'">
          <TransferFromGasSource :currency="currency" @feedGasManually="refresh()"/>
        </b-nav-item>
        <b-nav-item>
          <b-button variant="info" size="sm" v-b-modal.sendMoney v-if="pendingTransaction==null">Start Withdrawal</b-button>
        </b-nav-item>
        <b-nav-item>
          <b-button variant="danger" size="sm" v-b-modal.publish v-if="pendingTransaction!=null">Confirm Withdrawal</b-button>
        </b-nav-item>
      </b-nav-form>
    </b-nav>
    <b-table striped small :responsive="true" :fields="fields" :items="items" >
      <template #cell(address)="data">
        <a target="_blank" class="address" :href="addressLink(data.item.address)">{{ data.item.address }} </a>
      </template>
      <template #cell(watching)="data">
        <b-button v-if="data.item.watching==false" @click="startWatchButton(data.item)" variant="info">Start</b-button>
        <span v-else>{{ formatExpireTime(data.item.watching) }}</span>
      </template>
      <template #cell(txid_n)="data">
        <a target="_blank" v-if="data.item.txid" class="address" :href="txLink(data.item.txid)">{{ txLabel(data.item.txid, data.item.n) }} </a>
        <span v-else></span>
      </template>
      <template #cell(deposits)="data">
        <router-link :to='depositBasePath() + data.item.accountID'>View</router-link>
      </template>
    </b-table>

  <b-modal id="addaccount" title="Add Account" ok-title="Add" @ok="addUser">
    <b-form-input v-model="newAccountID" placeholder="Enter new account id"></b-form-input>
  </b-modal>
  <b-modal id="sendMoney" title="Prepare Withdrawal Transaction" ok-title="OK" @ok="sendMoney">
  <b-row >
    <b-col sm="3">
      <label for="input-none">Destination</label>
    </b-col>
    <b-col sm="9">
      <b-form-input v-model="destination" placeholder="Destination Address/AccountID"></b-form-input>
    </b-col>
  </b-row>
  <b-row >
    <b-col sm="3">
      <label for="input-none">Amount</label>
    </b-col>
    <b-col sm="9">
      <b-form-input type="number" v-model="sendAmount" placeholder="0"></b-form-input>
    </b-col>
  </b-row>
  <b-row >
    <b-col sm="3">
      <label for="input-none">Reference Text</label>
    </b-col>
    <b-col sm="9">
      <b-form-input type="text" v-model="reference" placeholder="arbitral text to track withdrawal"></b-form-input>
    </b-col>
  </b-row>

  </b-modal>
  <!-- このstaticが肝の気がするが試行錯誤しすぎてよくわからん -->
  <b-modal :static="true" id="publish" title="Confirm Publishing to Public Blockchain" ok-title="Publish" @ok="publishTx" @hide="discardTx"  >
    <template>
      <PendingTransaction :tx="pendingTransaction" :currency="currency"/>
    </template>
    <template #modal-footer="{ ok, cancel, hide }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="danger" @click="ok()">
        Publish (NOT CANCELLABLE)
      </b-button>
      <b-button size="sm" variant="info" @click="cancel()">
        Return
      </b-button>
      <!-- Button with custom close trigger value -->
      <b-button size="sm" variant="secondary" @click="hide('discard')">
        Discard This Withdrawal
      </b-button>
    </template>
 </b-modal>

  </div>
</template>

<script>
import PendingTransaction from "./PendingTransaction.vue";
import ConfigreFee from "./ConfigureFee.vue";
import TransferFromGasSource from "./TransferFromGasSource.vue";

function getTableFields(currency) {
  const t = [
        {key:"accountID", label:"Account ID"},
        {key:"address", label:"Address"},
        {key:"amount", label:"Amount"}];
  if(currency=="BTC")
    t.push({key:"txid_n", label:"UTXO"});
  if(currency=="USDT")
    t.push({key:"gasForTokenWithdraw", label:"Gas"});
  
  t.push({key:"watching", label:"Watching"});
  t.push({key:"deposits", label:"Deposits"});
  return t;

}

export default {
  name: 'UserList',
  props: ["userList", "currency"],
  data: function() {
    return {
      fields: [], //データがきたときにセット
      items: null,
      currencyF: null,
      newAccountID: "",
      depositBalance:"-",
      merchantBalance:"-",
      blockchainBalance:"-",
      gasSourceAddress:"-",
      gasSourceBalance:"-",
      destination : "",
      sendAmount: 0,
      reference:"",
      pendingTransaction: null,
      positiveOnly: false,
      testNum: 8
    }
  },
  components: { PendingTransaction, ConfigreFee, TransferFromGasSource
  },
  created: function() {
    this.currencyF = this.$parent.currencyOf(this.currency); //関数群をもってくる
  },
  mounted: function() {
    this.$parent.refresh(this.currency);
  },
  watch: {
    userList: function(ul) {
      this.fields = getTableFields(this.currency);
      this.items = ul? ul.list : [];

      if(ul.balance) {
        this.currencyF = this.$parent.currencyOf(this.currency); //関数群をもってくる
        this.depositBalance = ul.balance.total;
        this.merchantBalance = ul.balance.merchant;
        this.blockchainBalance = ul.balance.blockchain;
        if(this.currency=="USDT") {
          this.gasSourceAddress = ul.balance.USDTGasSourceAddress;
          this.gasSourceBalance = ul.balance.USDTGasSourceETHBalance;
        }
      }

      this.pendingTransaction = ul.pendingTransaction;
      
    },
    positiveOnly: function() {
      this.refresh();
    }
  },
  methods: {
    addUser: function() {
      this.$root.hapi.watchAccount(this.currency, this.newAccountID, (err, data) => {
        if(err)
          this.$parent.showError(err);
        else {
          this.$parent.refresh(this.currency, this.positiveOnly);
          this.$parent.showInfo("new account added: " + JSON.stringify(data));
        }
      });
    },
    refresh: function() {
      this.$parent.refresh(this.currency, this.positiveOnly);
    },
    formatExpireTime: function(t) {
      const now = Math.floor(new Date().getTime()/1000);
      const sec = t - now; //秒単位で
      const hour = Math.floor(sec / 3600);
      const min = Math.floor((sec - hour*3600) / 60);
      return hour.toFixed(0) + "h" + min.toFixed(0) + "m";
    },
    refreshButton: function() {
      this.refresh();
    },
    withdrawHistoryButton: function() {
      this.$router.push("/withdrawals/" + this.currency);
    },
    depositBasePath:function() {
      return "/deposits/" + this.currency + "/";
    },
    sendMoney: function() {
        this.$root.hapi.withdraw(this.currency, this.destination, this.sendAmount, this.reference, (err) => {
          if(err)
            this.$parent.showError(err);
          else {
            this.refresh();
          }
        });
      },
    startWatchButton: function(e) {
      this.$root.hapi.watchAccount(this.currency, e.accountID, (err)=>{
        if(!err)
          this.refresh();
      });
    },
    traverseBlockchainButton: function() {
      this.$root.hapi.checkBlockchain(this.$parent.merchantID, (err, data)=>{
        console.log("blockchain %o", data);
        if(!err)
          this.refresh();
      });
    },
    addressLink: function(a) { return this.currencyF.addressLink(a); },
    txLink: function(tx) { return this.currencyF.txLink(tx); },
    txLabel: function(tx, n) { return this.currencyF.txLabel(tx, n); },
    shortAddress: function(a) { return this.currencyF.shortAddress(a); },
    discardTx: function(t) {
      if(t.trigger=="discard") {
        console.log("discardTx %o", t);
        this.$root.hapi.withdrawalTransaction(this.currency, "discard", (err) => {
          if(err)
            this.$parent.showError(err);
          else {
            console.log("discard response");
            this.refresh();
          }
        });
      }
    },
    publishTx: function() {
      console.log("publishTx ");
      this.$root.hapi.withdrawalTransaction(this.currency, "go", (err) => {
        if(err)
          this.$parent.showError(err);
        else {
          this.$parent.showInfo("transaction is published successfully");
          this.refresh();
        }
      });
    }
  }
}
</script>

<style scoped>
.address {
  font-family: Monospace;
}
.money {
  font-family: Monospace;
}
.totalMoney {
  font-family: Monospace;
  font-weight: bold;
  margin-right: 20px;
}
</style>
