<template>
   <!-- toggaleableで画面が狭いときの折り畳み、スマホ対応 
   fixed="top"以外だとレイアウト調整が困難、これをflexboxで指定するのは面倒のようだ
   -->
    <b-navbar toggleable="sm" type="dark" variant="primary" fixed="top"> 
      <b-navbar-brand href="/">HKWallet </b-navbar-brand>
      <p class="merchant"><span class="merchantText">Merchant ID {{ merchantID }}</span></p>
      <b-form-radio-group
        v-model="currency2"
        :options="currencyList"
        @input="onCurrencyChanged"
        button-variant="outline-success"
        buttons
      ></b-form-radio-group>

    </b-navbar>

</template>

<script>

export default {
  name: 'TopNavigation',
  props: ["merchantID", "currency"],
  data: function() {
    return {
      currencyList:["ETH", "BTC", "USDT", "TRXUSDT"],
      currency2: this.currency, //inner data
    };
  },
  methods: {
    onCurrencyChanged: function(v) {
      this.$emit("onCurrencyChanged", v);
    }
  }
}
</script>
<style scoped>
.navbar {
    padding-bottom: 0;
}
.merchant {
  margin-right:30px;
  margin-top:20px;
  font-size:1.2em;
  color:lightgray;
}
.merchantText {
  vertical-align: bottom;
}
</style>