<template>
  <div>
    <b-button size="sm" variant="info" v-b-modal.transferFromGasSource>Transfer from Gas Source...</b-button>
    <b-modal id="transferFromGasSource" title="Transfer from Gas Source" ok-title="Send" @ok="send">
        <b-row >
            <b-col sm="6">
            <label for="input-none">Destination Account ID</label>
            </b-col>
            <b-col sm="6">
            <b-form-input v-model="destination" ></b-form-input>
            </b-col>
        </b-row>
        <b-row >
            <b-col sm="6">
            <label for="input-none">Amount</label>
            </b-col>
            <b-col sm="6">
            <b-form-input type="number" v-model="amount" ></b-form-input>
            </b-col>
        </b-row>

    </b-modal>

  </div>
</template>
<script>
export default {
  name: 'TransferFromGasSource',
  props: ["currency"],
  data: function() {
      return {
          destination:"",
          amount:0
      };
  },
  watch: {
  },
  methods: {
      send:function() {
          this.$root.hapi.feedGasManually(this.currency, this.destination, this.amount, (err, data)=>{
              console.log("feedGasManually response %o", data);

              if(err) this.$root.app.showError(err);
              else {
                  this.$root.app.showInfo("transaction is published");
                  this.$emit("feedGasManually");
              }
          });
      }
  }
}
</script>
