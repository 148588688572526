export default class Currencies {
    constructor() {
        this.ETH = {
            addressLink: function(a) { return "https://etherscan.io/address/" + a; },
            shortAddress: function(a) { return a.substring(0, 6)+"..."+a.substring(a.length-6); },
            txLink: function(tx) {return "https://etherscan.io/tx/" + tx; },
            txLabel: function(tx) { return tx.substring(0,4)+"..."+tx.substring(tx.length-4); }
        };
        this.USDT = {
            addressLink: function(a) { return "https://etherscan.io/address/" + a; },
            shortAddress: function(a) { return a.substring(0, 6)+"..."+a.substring(a.length-6); },
            txLink: function(tx) {return "https://etherscan.io/tx/" + tx; },
            txLabel: function(tx) { return tx.substring(0,4)+"..."+tx.substring(tx.length-4); }
        };
        this.TRXUSDT = {
            addressLink: function(a) { return "https://tronscan.org/#/address/" + a; },
            shortAddress: function(a) { return a.substring(0, 6)+"..."+a.substring(a.length-6); },
            txLink: function(tx) {return "https://tronscan.org/#/transaction/" + tx; },
            txLabel: function(tx) { return tx.substring(0,4)+"..."+tx.substring(tx.length-4); }
        };
        this.BTC = {
            addressLink: function(a) { return "https://www.blockchain.com/btc/address/" + a; },
            shortAddress: function(a) { return a.substring(0, 6)+"..."+a.substring(a.length-6); },
            txLink: function(tx) {return "https://www.blockchain.com/btc/tx/" + tx; },
            txLabel: function(tx, n) { return tx.substring(0,4)+"..."+tx.substring(tx.length-4) + "@" + n; }
        };
    }
}