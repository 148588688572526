<template>
  <div class="c">
    <p>Withdraw History / <router-link :to='"/accounts/"+this.currency'>Back</router-link></p>
    <b-row >
       <b-col sm="3">
        <b-checkbox v-model="dateBeginFlag">Limit Start of Date Range</b-checkbox>
       </b-col>
       <b-col sm="6">
        <b-form-datepicker v-model="dateBegin" :disabled="!dateBeginFlag" class="mb-2"></b-form-datepicker>
       </b-col>
    </b-row>
    <b-row >
       <b-col sm="3">
        <b-checkbox v-model="dateEndFlag">Limit End of Date Range</b-checkbox>
       </b-col>
       <b-col sm="6">
        <b-form-datepicker v-model="dateEnd" :disabled="!dateEndFlag" class="mb-2"></b-form-datepicker>
       </b-col>
    </b-row>
    <b-button @click="refresh()" variant="primary">Update</b-button>
    <p/>

    <b-table striped small :responsive="true" :fields="fields" :items="history" >
      <template #cell(dateTime)="data">
          {{ data.item.dateTime }}
      </template>
      <template #cell(txid)="data">
          <a class="address" target="_blank" :href='txLink(data.item.txid)'>{{txLabel(data.item.txid, data.item.n)}}</a>
      </template>
      <template #cell(opponent)="data">
          <a class="address" target="_blank" :href='addressLink(data.item.opponent)'>{{data.item.opponent}}</a>
      </template>
    </b-table>

  </div>
</template>
<script>

export default {
  name: 'WithdrawList',
  props: ["currency"],
  data: function() {
    return {
      fields: [
        {key:"dateTime", label:"Time"},
        {key:"amount", label:"Amount"},
        {key:"txid", label:"TXID"},
        {key:"opponent", label:"Opponent"},
        {key:"reference", label:"Reference Text"}
      ],
      history: null,
      currencyF : null,
      dateBeginFlag: false,
      dateEndFlag: false,
      dateBegin: null,
      dateEnd:null
    }
  },
  components: {
  },
  mounted: function() {
    this.refresh();
  },
  methods : {
      txLink: function(tx) {
        return this.currencyF.txLink(tx);
      },
      txLabel: function(tx) {
        return this.currencyF.txLabel(tx);
      },
      addressLink: function(a) {
        return this.currencyF.addressLink(a);
      },
      refresh: function() {
        const range = {};
        if(this.dateBeginFlag && this.dateBegin) range.unixtime_from = toUnixTime(this.dateBegin);
        if(this.dateEndFlag && this.dateEnd) range.unixtime_to = toUnixTime(this.dateEnd) + 3600*24;
        this.$root.hapi.withdrawHistory(this.currency, range, (err, data) => {
          console.log("withdraw history %o", data);
          this.currencyF = this.$parent.currencyOf(this.currency); //関数群をもってくる
          this.history = data? data.history : [];
        });
      }
       
  }
}

function toUnixTime(d) {
  const year = parseInt(d.substring(0,4));
  const month = parseInt(d.substring(5,2));
  const day = parseInt(d.substring(8,2));
  return Math.floor(new Date(year,month,day).getTime() / 1000);
}
</script>

<style scoped>
.address {
  font-family: Monospace;
}
.money {
  font-family: Monospace;
}
</style>
