import Vue from 'vue'
import Notifications from 'vue-notification'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import App from './App.vue'

import router from './router'
import HotWalletAPI from './lib/HotWalletAPI'
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);

const preferences = {
};

new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      hapi: new HotWalletAPI(this, "https://7c20817dp1.execute-api.ap-northeast-1.amazonaws.com/dev/hotwallet-dev"), //全部載せAPI
      preferences,
      app: null
    }
  },
  methods: {
    setApp: function(app) {
      this.app = app;
    }
  }
}).$mount('#app')
