<template>
  <div class="c">
    <p>Deposit History / {{ accountID }} / <router-link :to='"/accounts/"+this.currency'>Back</router-link></p>
    <b-table striped small :responsive="true" :fields="fields" :items="history" >
      <template #cell(dateTime)="data">
          {{ data.item.dateTime }}
      </template>
      <template #cell(txid)="data">
          <a class="address" target="_blank" :href='txLink(data.item.txid)'>{{txLabel(data.item.txid, data.item.n)}}</a>
      </template>
      <template #cell(opponent)="data">
          <a class="address" target="_blank" :href='addressLink(data.item.opponent)'>{{data.item.opponent}}</a>
      </template>
    </b-table>

  </div>
</template>
<script>

export default {
  name: 'HistoryList',
  props: ["accountID","currency"],
  data: function() {
    return {
      fields: [
        {key:"dateTime", label:"Time"},
        {key:"amount", label:"Amount"},
        {key:"txid", label:"TXID"},
        {key:"opponent", label:"Opponent"}
      ],
      history: null,
      currencyF : null,
    }
  },
  components: {
  },
  mounted: function() {
    this.$root.hapi.depositHistory(this.currency, this.accountID, (err, account) => {
      console.log("response history %o", account);
      this.currencyF = this.$parent.currencyOf(this.currency); //関数群をもってくる
      this.history = account? account.history : [];
    });
  },
  methods : {
      txLink: function(tx) {
        return this.currencyF.txLink(tx);
      },
      txLabel: function(tx, n) {
        return this.currencyF.txLabel(tx, n);
      },
     addressLink: function(a) {
        return this.currencyF.addressLink(a);
      }
       
  }
}
</script>

<style scoped>
.address {
  font-family: Monospace;
}
.money {
  font-family: Monospace;
}
</style>
