<template>
  <div>
    ログインに成功しました。ユーザリスト画面に移動します...
  </div>
</template>
<script>

export default {
  name: 'NewToken',
  props: ["token"],
  data: function() {
    return {
    }
  },
  mounted: function() {
    this.$root.hapi.setToken(this.token);
    //ブラウザのアドレスバーから見えないようリダイレクト
    window.setTimeout(() => this.$router.push("/accounts/ETH"), 3000);
  },
  method: {
  }
}

</script>
<style scoped>
</style>
