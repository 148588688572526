<template>
  <div>
    <h4>From:(Accounts are selected by HKWallet)</h4>
    <b-table class="table1" small :responsive="true" :fields="fromFields" :items="from" >
      <template #cell(consumeBalance)="data">
        <span class="money">{{data.item.consumeBalance}}</span>
      </template>
    </b-table>
    <h4>To:</h4>
    <b-table class="table1" small :responsive="true" :fields="toFields" :items="to" >
      <template #cell(value)="data">
        <span class="money">{{data.item.value}}</span>
      </template>
    </b-table>
    <h4>Changes of Balances</h4>
    <b-table class="table1" small :responsive="true" :fields="summaryFields" :items="summary" >
      <template #cell(value)="data">
        <span class="money" :class="data.item.sub? 'subItem' : ''">{{data.item.value}}</span>
      </template>
      <template #cell(name)="data">
        <span :class="data.item.sub? 'subItem' : ''">{{data.item.name}}</span>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: 'PendingTransaction',
  props: ["tx", "currency"],
  data: function() {
    return {
        fromFields: [
            {key:"accountID", label:"Account ID"},
            {key:"consumeBalance", label:"Consume Balance"}
        ],
        from:[],
        toFields: [
            {key:"address", label:"Address"},
            {key:"value", label:"Value"}
        ],
        to:[],
        summaryFields: [
          {key:"name", label:"term"},
          {key:"value", label:"value"}
        ],
        summary:[],
        merchantFee:0,
        networkFee:0
    }
  },
  methods: {
  },
  watch: {
      tx: function(x) {
          if(!x) return;
          console.log("update pendingTx %o", x);
          const app= this.$root.app;
          this.currencyF = app.currencyOf(this.currency); //関数群をもってくる
      
          this.merchantFee=x.merchantFee;
          this.networkFee = x.networkFee;
          this.from = x.fromList;
          this.to = x.toList.map( e => { 
            const change = x.changeAddress==e.address;
            return { address:this.currencyF.shortAddress(e.address) + (change? "(change)" : ""), 
              value:e.value }; 
            });
          const txCount = (this.currency=="ETH" || this.currency=="USDT")? x.fromList.length : 1;
          this.to.push({address:"Transaction Count", value:txCount}, 
            {address:"Network Fee", value:x.networkFee});

          const s = x.summary;
          this.summary = [
            {name:"Total User Deposit", value:s.accountBalanceChange},
            {name:"To Beneficiary", value:"-"+s.beneficiaryValue, sub:true},
            {name:"Merchant Fee", value:"-"+x.merchantFee, sub:true},
            {name:"Merchant Balance", value:"+"+s.merchantBalanceChange},
            {name:"Reference Text", value:s.reference}
          ];
      }
  }
}

</script>
<style scoped>
.table1 {
  text-align: left;
}
.money {
    font-family: "monospace";
}
.subItem {
  margin-left:15px;
  color:darkgray;
}
</style>
