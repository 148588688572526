<template>
  <div id="app">
    <TopNavigation class="topnav" :merchantID="merchantID" :currency="currentCurrency()" @onCurrencyChanged="onCurrencyChanged"/>
    <div class="app-content">
      <router-view :userList="userList"/>
    </div>
    <notifications group="app"/>
  </div>
</template>

<script>
import TopNavigation from "./views/TopNavigation.vue";
import Currencies from "./currencies.js";
const currencies = new Currencies();

//切り替え中の表示
const emptyBalance = {
  list:[],
  balance:{blockchain:"", merchant:"", total:""},
  merchantID:"",
  withdrawalFee:{}
}

export default {
  name: 'App',
  data: function() {
    return {
      userList: null,
      merchantID: ""
    };
  },
  components: {
    TopNavigation
  },
  mounted: function() {
    this.$root.setApp(this);
    document.title = "HKWallet";
  },
  methods: {
    showError: function(msg) {
      this.$notify({group:"app", title:"Error", type:"error", duration:10000, text:msg});
    },
    showInfo: function(msg) {
      this.$notify({group:"app", title:"note", type:"info", duration:5000, text:msg});
    },
    setCurrentBalance: function(d) {
      console.log("currentBalance %o", d);
      this.userList = d;
      this.merchantID = d.merchantID;
    },
    currencyOf: function(currency) {
      const c = currencies[currency];
      if(!c) throw "currency " + currency + " not found";
      return c;
    },
    refresh: function(currency, positiveOnly) {
      this.$root.hapi.queryBalance( currency, positiveOnly? true : false, (err, data) => {
        if(err)
          this.showError(err);
        else {
          this.setCurrentBalance(data);
        }
      });
    },
    currentCurrency: function() {
      const p = this.$router.currentRoute;
      if(p) return p.params.currency;
      else return "ETH"; //とりあえずで
    },
    onCurrencyChanged: function(c) {
      const p = this.currentCurrency();
      //console.log("currency changed %s => %s", p, c);
      this.$router.push(this.$router.currentRoute.path.replace("/"+p, "/"+c));
      this.userList = emptyBalance; //切り替え後のデータが来るまで空にしておく
      this.refresh(c);
    }
  }
}

</script>

<style scoped>
/* スマホ */
@media (max-width: 512px) { 
.app-content {
  /* こいつの横幅が、一部のスマホで100%にならない。謎 */
  margin: 0 0 0 0;
  width:100%;
  height:100%;
  flex-direction: column;
  padding-top: 60px;  /* これはTopNavigationの高さとほぼ同じであること スマホ機種による差あり */
}
.app-main { /* router-viewの本体 */
  width:100%;
  height:100%;
  text-align: left;
}
}


/* iPad以上 */
@media (min-width: 512px) { 
.app-content {
  /* こいつの横幅が、一部のスマホで100%にならない。謎 */
  margin: 0 0 0 0;
  width:100%;
  height:100%;
  padding-top: 60px;  /* これはTopNavigationの高さとほぼ同じであること スマホ機種による差あり */
}
/*
.app-side {
  flex: 0 0 150px; /* この幅をPreference化したい 
  background-color: lightblue;
  overflow: auto;
  border-radius: 5px;
  border: 2px solid black;
  padding: 2px;
}
*/
.app-main { /* router-viewの本体 */
  width:100%;
  height:100%;
  text-align: left;
}
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

.topnav {
  height: 60px;
}

body {
}
/*
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
*/
</style>
