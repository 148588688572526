import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About.vue'
import NewToken from '../views/NewToken.vue'
import UserList from '../views/UserList.vue'
import HistoryList from '../views/HistoryList.vue'
import WithdrawList from '../views/WithdrawList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'About',
    component: About,
    meta: { title: 'HKWallet Login' }
  },
  {
    path: '/accounts/:currency',
    name: 'UserList',
    component: UserList,
    props: true,
    meta: { title: 'HKWallet' }
  },
  {
    path: '/withdrawals/:currency',
    name: 'WithdrawList',
    component: WithdrawList,
    props: true,
    meta: { title: 'HKWallet' }
  },
  {
    path: '/deposits/:currency/:accountID',
    name: 'History',
    component: HistoryList,
    props: true,
    meta: { title: 'HKWallet' }
  },
  {
    path: '/newtoken/:token',
    name: 'NewToken',
    component: NewToken,
    props: true,
    meta: { title: 'HKWallet' }
  }
]

const router = new VueRouter({
  routes
})

export default router
