//サーバとのやりとり担当
const axios = require('axios');

export default class HotWalletAPI {
    constructor(root, base_url) {
      this.root = root;
      this.base = base_url;
      this.setToken("sayu-sdfhjwoeoibor"); //開発版
    }
    setToken(token) {
        //axiosのヘッダ
        this.config = {"headers": {"Authorization": "Bearer " + token} };
    }
    status(cb) {
        try {
            axios.post(this.base, {method:"status"}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { //HTTP 400,500はこっちになる
            this.handleErrorCase(e, cb);
        }
    }
    queryBalance(currency, positiveOnly, cb) {
        try {
            axios.post(this.base, {method:"queryBalance", currency, positiveOnly}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { //HTTP 400,500はこっちになる
            this.handleErrorCase(e, cb);
        }
    }
    watchAccount(currency, account, cb) {
        try {
            axios.post(this.base, {method:"watchAccount", currency, account}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { //HTTP 400,500はこっちになる
            this.handleErrorCase(e, cb);
        }
    }
    depositHistory(currency, account, cb) {
        try {
            axios.post(this.base, {method:"depositHistory", currency, account}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { 
            this.handleErrorCase(e, cb);
        }
    }
    withdrawHistory(currency, range, cb) {
        try {
            const arg = {method:"withdrawalHistory", currency};
            axios.post(this.base, Object.assign(arg, range), this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { 
            this.handleErrorCase(e, cb);
        }
    }
    feedGasManually(currency, destination, amount, cb) {
        try {
            axios.post(this.base, {method:"feedGasManually", currency, destination, amount}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { 
            this.handleErrorCase(e, cb);
        }
    }
    withdraw(currency, destination, amount, reference, cb) {
        try {
            axios.post(this.base, {method:"withdraw", currency, destination, amount, reference}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { 
            this.handleErrorCase(e, cb);
        }
    }
    withdrawalTransaction(currency, action, cb) {
        try {
            axios.post(this.base, {method:"withdrawalTransaction", currency, action}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { 
            this.handleErrorCase(e, cb);
        }
    }
    checkBlockchain(merchantID, cb) {
        try {
            axios.post(this.base, {method:"checkBlockchain", merchantID}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { 
            this.handleErrorCase(e, cb);
        }
    }
    setFee(currency, merchantFee , blockchainFee, cb) {
        try {
            axios.post(this.base, {method:"setFee", currency, merchantFee , blockchainFee}, this.config).then(r => cb(null, r.data)).catch(e => this.handleErrorCase(e, cb));
        }
        catch(e) { 
            this.handleErrorCase(e, cb);
        }
    }

    handleErrorCase(e, cb) {
        console.log(e);
        //console.dir(e); これはなぜかうまくハンドルできない。フィールドを参照すると大丈夫なんだが
        const status = e.response.status;
        if(status==400) {
            console.error(e.response.data.error);
            cb(e.response.data.error); //BellagioAPIの仕様でこうなる。errorにリクエストの何がまずいのかが入る
        }
        else {
            cb(e.response.data); //500系は全部
        }
    }
}
