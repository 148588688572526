<template>
  <div class="about">
    <h1>Welcome to HKWallet</h1>
    <b-nav align="center">
      <b-nav-item v-if="this.environment=='auth-local'">
        <b-button to="/accounts/ETH" variant="primary">Development(localhost only)</b-button>
      </b-nav-item>
      <b-nav-item>
        <b-button variant="primary" @click="goGoogle()">Continue With Google Account</b-button>
      </b-nav-item>
    </b-nav>
  </div>
</template>
<script>

//Google認証に通ると、APIGatewayのmethod=oauth-local または oauth-stagingにリダイレクトし、そこがそれぞれ
//http://localhost:8080/#/newtoken/...
//https://d1jg0av5yz2jij.cloudfront.net/#/newtoken/...
//にリダイレクトしてアプリに戻る
const googleAuthURL = "https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=offline&client_id=631024747406-rpaup81a6q42sbh556lvecqimvq6g0q5.apps.googleusercontent.com&redirect_uri=https%3A%2F%2F7c20817dp1.execute-api.ap-northeast-1.amazonaws.com%2Fdev%2FoAUTH_METHOD&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&approval_prompt=auto&include_granted_scopes=true";

export default {
  name: 'About',
  data: function() {
    const host = window.location.hostname;
    return {
      environment: host=="localhost"? "auth-local" : "auth-staging"
    }
  },
  mounted: function() {
    console.log("home mount %o", window.location);
  },
  methods: {
    goGoogle: function() {
      window.location = googleAuthURL.replace("AUTH_METHOD", this.environment); //認証後のAPI Gatewayの行先をセット
    }
  }
}

</script>
<style scoped>
.buttons {
  width:50%;
  margin:0 auto;
}
.spacer {
  width: 30px;
}
</style>
